/*@import url("https://fonts.googleapis.com/css2?family=Alexandria&family=Cairo&display=swap");*/
.App {
  background-color: #0d0d0d;
  color: #424242;
  padding: 0px;
  /*font-family: "Cairo", sans-serif !important;*/
}

.body_back {
  background-image: linear-gradient(rgba(0, 0, 0, 0.504), rgba(0, 0, 0, 0.504)),
    url(../src/assets/imgs/body_back.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: -142px;
  min-height: 100vh;
  padding-top: 60px;
  padding-bottom: 40px;
}

.admin_back {
  background-image: linear-gradient(rgba(0, 0, 0, 0.504), rgba(0, 0, 0, 0.504)),
    url(../src/assets/imgs/body_back.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/***** Navbar style ***/
.navbar_button {
  border-radius: 0px !important;
  font-size: 16px !important;
  /* margin-bottom: -1px !important; */
  /* padding-bottom: 14px !important; */
}
.navbar_button:hover,
.active_page {
  background-color: rgba(255, 102, 0, 0) !important;
  /* border-bottom: 4px solid #fff !important;
  padding-bottom: 12px !important; */
  color: #000000 !important;
}

.right {
  text-align: right !important;
}

.account_menu {
  left: unset !important;
  right: -85px !important;
  width: 300px !important;
  top: 40px !important;
}
@media only screen and (max-width: 900px) {
  .navbar_button:hover,
  .active_page {
    border-bottom: 2px solid #DD942B !important;
    padding-bottom: 4px !important;
  }
}

/***** Buttons style ***/
button {
  text-transform: capitalize !important;
  font-family: "Cairo", sans-serif !important;
}

.outlineOrange_button {
  border: 1px solid #DD942B !important;
  color: #DD942B !important;
  font-weight: 500 !important;
}

.outlinePrimary_button {
  border: 1px solid #000000 !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.outlinePrimary_button:hover {
  background-color: #000000 !important;
  color: #DD942B !important;
}

.primary_button {
  color: #000000 !important;
  font-weight: 500 !important;
  background-color: #DD942B !important;
}

.orangePrimary_button {
  border: 1px solid #DD942B !important;
  font-weight: 500 !important;
  color: #fff !important;
  border-radius: 15px !important;
}

.orangePrimary_button:hover {
  background-color: #DD942B !important;
  color: #fff !important;
}
.orange_icon_button {
  color: #fff !important;
  font-size: 26px !important;
  background-color: #DD942B !important;
  border-radius: 15px !important;
  height: 25px !important;
  padding-top: 2px !important;
  padding-bottom: 8px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}
/***** Form style ***/

.MuiSlider-thumb {
  color: #DD942B;
}
.MuiSlider-rail {
  color: gray;
}
.MuiSlider-track {
  color: #DD942B;
}

.review_progress .css-5xe99f-MuiLinearProgress-bar1 {
  background-color: #f2c94c !important;
}
.en_radius .MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.en_radius .MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ar_radius .MuiTableRow-root td:first-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ar_radius .MuiTableRow-root td:last-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.MuiTableRow-root tr {
  margin-top: 10px !important;
}
.dark_table {
  border-collapse: separate !important;
  border-spacing: 0px 10px !important;
}
.dark_table_scroll {
  height: 400px !important;
  overflow-y: scroll !important;
}
.dark_table_scroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
.dark_table_scroll::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #dd942b;
}
.divider::after,
.divider::before {
  background-color: #ffffff;
  height: 14px !important;
}
.divider {
  margin: 0px !important;
}

.verify_character {
  border: none;
  font-size: 20px;
  border-radius: 12px;

  /* light theme */
  color: white;
  background-color: black;
  box-shadow: 0 2px 0 #e4e2f5;
  border: 1px solid #e0e0e0;
}
.character--selected {
  border: 2px solid #dd942b !important;
  border-color: #dd942b !important;
}

.camera {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding-top: 20px;
  z-index: 2;
  top: 0;
  display: none;
  text-align: center !important;
}
.camera_div {
  position: relative;
  z-index: 1;
  padding: 0px !important;
  display: inline-block;
}
.camera_div:hover .camera {
  display: block;
}

.camera_div:hover {
  opacity: 0.5;
  cursor: pointer;
}
.camera_div img {
  border-radius: 50%;
}

.loadingContainer {
  display: "flex";
  align-items: center;
  justify-content: center;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 45%;
  padding-right: 45%;
}
.table_style svg {
  fill: white !important;
}
.table_style input {
  color: white !important;
}
.hover_headCell:hover span,
.hover_headCell span,
.hover_headCell span svg {
  color: #DD942B !important;
}
#scroll_tier {
  height: 300px;
  overflow-y: scroll;
}
#scroll_tier::-webkit-scrollbar {
  width: 10px !important;
  border-radius: 10px !important;
  background-color: #f5f5f5 !important;
}
#scroll_tier::-webkit-scrollbar-thumb {
  border-radius: 7px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  background-color: #dd942b !important;
}
/***** Side Bar style ***/

.active_item {
  background: #DD942B !important;
  color: #ffffff !important;
  border-radius: 12px !important;
  font-family: "Alexandria" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.sideBar_item {
  border-radius: 12px !important;
  font-family: "Alexandria" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: white !important;
}
.active_subItem {
  background: #edf4f4 !important;
  font-family: "Alexandria" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #DD942B !important;
  border-radius: 8px !important;
}
.sideBar_subItem {
  font-family: "Alexandria" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #333333 !important;
  border-radius: 8px !important;
}
.file_uploader label {
  min-width: 100% !important;
  max-width: 100% !important;
  margin-top: 20px;
  border: 2px dashed #DD942B !important;
}
.file_uploader svg path {
  fill: #DD942B !important;
}
.file_uploader span {
  color: white !important;
}
.dashboard_card {
  background-color: #ff5d1877;
  /* color: white; */
  border-radius: 30px;
}
.line_chart text {
  fill: white !important;
}
.line_chart rect {
  /* width: 90% !important; */
}
.client_dialog .MuiPaper-root {
  background-color: #2b2727 !important;
  color: white !important;
}
.link:hover{
  cursor: pointer;
  text-decoration: underline;
}

.input_with_icon{
  background-color: #000000 !important;
  color: white !important;
}