.qr-reader {
  width: 25%;
  height: 50vh;
  margin: 0px auto;
  position: relative;
}

.qr-reader video {
  width: 68%;
  height: 73%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
  .qr-reader video {
    width: 82%;
    height: 79%;

  }
}
